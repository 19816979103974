import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"

import { ContentContainer, StyledLink } from "../components/shared-styles"

const StyledHeader = styled.header`
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
`

const Container = styled(ContentContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.h1`
  font-size: 1.62671rem;
  margin: 0;

  a {
    box-shadow: none;
    color: black;
    text-decoration: none;
  }
`

const StyledNav = styled.nav`
  a:first-of-type {
    margin-right: 1em;
  }
`

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <Container>
      <Logo>
        <Link to="/">{siteTitle}</Link>
      </Logo>
      <StyledNav>
        <StyledLink to="/about">About</StyledLink>
        <StyledLink to="/contact">Contact</StyledLink>
      </StyledNav>
    </Container>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
