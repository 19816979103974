import styled from "styled-components"
import { Link } from "gatsby"
import settings from "../utils/settings"
import colors from "../utils/colors"

export const SiteContainer = styled.div`
  margin: 0 auto;
  max-width: ${settings.siteWidth};
  padding: 1rem;
`

export const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: ${settings.contentWidth};
  padding: 1rem;
`

export const PageHeading = styled.h2`
  margin-top: 0.33rem;
`

export const StyledDate = styled.p`
  margin-bottom: 0.5rem;
  font-style: italic;
`

export const StyledLink = styled(Link)`
  color: ${colors.blue};
  text-decoration: none;

  &:visited {
    color: ${colors.blue};
  }
  &:hover {
    color: ${colors.red};
  }
`
