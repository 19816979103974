import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  ContentContainer,
  PageHeading,
  StyledDate,
  StyledLink,
} from "../components/shared-styles"
import formatDate from "../utils/formatDate"

const Post = ({ data }) => {
  const {
    title,
    createdAt,
    childContentfulBlogPostBodyTextNode,
    image,
  } = data.contentfulBlogPost

  return (
    <Layout>
      <SEO title={title} />
      <ContentContainer>
        {image && <img alt={title} src={image.file.url} />}
        <StyledDate>{formatDate(createdAt)}</StyledDate>
        <PageHeading>{title}</PageHeading>
        <div
          dangerouslySetInnerHTML={{
            __html:
              childContentfulBlogPostBodyTextNode.childMarkdownRemark.html,
          }}
        ></div>
        <StyledLink to="/blog">View more posts</StyledLink>
      </ContentContainer>
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      childContentfulBlogPostBodyTextNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
      }
      tags
      createdAt
    }
  }
`
